<template>
  <base-layout>
    <change-password></change-password>
  </base-layout>
</template>

<script>
import ChangePassword from '../components/auth/ChangePassword.vue'
import BaseLayout from '../components/base/BaseLayout.vue'
export default {
    name: 'change-password-page',
    components:{BaseLayout, ChangePassword}

}
</script>

<style>

</style>