<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title> Actualizar Contraseña </ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <form class="ion-padding" @submit.prevent="submitForm">
        <ion-list>
          <ion-item>
            <ion-label position="floating">Contraseña</ion-label>
            <ion-input type="password" required v-model="password" />
            <p v-if="password && !passwordValid">
              <ion-text color="danger"
                >La contraseña debe tener almenos un numero, una mayuscula y un
                caracter especial</ion-text
              >
            </p>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Confirmar Contraseña</ion-label>
            <ion-input type="password" required v-model="confirmPassword" />
            <p v-if="confirmPass">
              <ion-text color="danger">Las contraseñas no coinciden</ion-text>
            </p>
          </ion-item>
        </ion-list>
        <ion-button type="submit" expand="block" fill="outline"
          >Actualizar Contraseña</ion-button
        >
      </form>
    </ion-card-content>
  </ion-card>
</template>

<script>
import { toastController } from "@ionic/vue";
import {
  IonButton,
  IonList,
  IonItem,
  IonInput,
  IonLabel,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonText,
  loadingController,
} from "@ionic/vue";
import {} from "ionicons/icons";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "change-password",
  components: {
    IonButton,
    IonList,
    IonItem,
    IonInput,
    IonLabel,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonText,
  },
  data() {
    return {
      password: null,
      confirmPassword: null,
      userId: 0,
      timeout:1000,
    };
  },
  computed: {
    ...mapGetters("user", ["user"]),
    passwordValid() {
      return /(^(?=.*\d)(?=.*[\u0021-\u002b\u003c-\u0040])(?=.*[A-Z])(?=.*[a-z])\S{8,16}$)/.test(
        this.password
      );
    },
    confirmPass() {
      return this.password !== this.confirmPassword ? true : false;
    },
  },
  mounted() {
    this.verData();
  },
  methods: {
    ...mapActions("user", ["changePasword"]),
    async submitForm() {
      const data = {
        userId: this.userId,
        password: this.password,
      };
      const validate = await this.validatePassword();
      this.timeout = 100000;
      const loading = await loadingController.create({
          message: "Enviando información...",
          duration: this.timeout,
        });
        await loading.present();
      if (validate) {
        const result = await this.changePasword(data);
        
        if (result.status === 200) {
          await this.$router.replace("/home/Inicio");
          this.toatsColor = "light";
          this.toatsMessage = `¡Contraseña actualizada exitosamente!`;
          await this.openToast();
        } else {
          let errorMessate = "";
          const errors = await JSON.parse(JSON.stringify(result.data.errors));
          for (const i in errors) {
            errorMessate = await errors[i];
          }
          this.toatsColor = "danger";
          this.toatsMessage = errorMessate;
          await this.openToast();
        }
      } else {
        this.toatsColor = "danger";
        this.toatsMessage = "Las contraseñas no coinciden";
        await this.openToast();
      }
      this.password = "";
      this.confirmPassword = "";
      loading.dismiss();
    },
    async validatePassword() {
      const validate = this.password === this.confirmPassword ? true : false;
      return validate;
    },
    async openToast() {
      const toast = await toastController.create({
        message: this.toatsMessage,
        position: "middle",
        color: this.toatsColor,
        duration: 2000,
      });
      return toast.present();
    },
    verData() {
      this.userId = this.user.id;
    },
  },
};
</script>

<style>
</style>